
const initialState = {
    isFetching: false,
    error: false,
    isFetchingDetail: false,
    errorDetail: false,
    data: [],
    orderDetail: null,
    isOpenCancelModal: false,
    isFetchingCancel: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_ORDERS":
            return {
                ...state,
                isFetching: true
            }
        case "FETCH_ORDERS":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
        case "ERROR_ORDERS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "GET_ORDER_DETAIL":
            return {
                ...state,
                isFetchingDetail: true,
                errorDetail: false,
                isOpenCancelModal: false
            }
        case "FETCH_ORDER_DETAIL":
            return {
                ...state,
                isFetchingDetail: false,
                orderDetail: action.payload
            }
        case "ERROR_ORDER_DETAIL":
            return {
                ...state,
                orderDetail: null,
                isFetchingDetail: false,
                errorDetail: true
            }
        case "GET_CANCEL_ORDER":
            return {
                ...state,
                isFetchingCancel: true
            }
        case "FETCH_CANCEL_ORDER":
            return {
                ...state,
                isFetchingCancel: false,
                isOpenCancelModal: false,
                orderDetail: action.payload
            }
        case "ERROR_CANCEL_ORDER":
            return {
                ...state,
                isFetchingCancel: false,
                isOpenCancelModal: false
            }

        case "TOGGLE_CANCEL_MODAL":
            return {
                ...state,
                isOpenCancelModal: action.payload
            }
        default:
            return state
    }

}
