
const initialState = {
    isFetching: false,
    isFetchingCreate: false,
    error: false,
    isFetchingDetail: false,
    errorDetail: false,
    data: [],
    orderDetail: null,
    isOpenCancelModal: false,
    isFetchingCancel: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_REFUNDS":
            return {
                ...state,
                isFetching: true
            }
        case "FETCH_REFUNDS":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
        case "ERROR_REFUNDS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "GET_ORDER_DETAIL_FOR_REFUND":
            return {
                ...state,
                isFetchingDetail: true,
                orderDetail : null,
                isFetchingCreate: false,
                errorDetail: false,
                isOpenCancelModal: false
            }
        case "FETCH_ORDER_DETAIL_FOR_REFUND":
            return {
                ...state,
                isFetchingDetail: false,
                orderDetail: action.payload
            }
        case "ERROR_ORDER_DETAIL_FOR_REFUND":
            return {
                ...state,
                orderDetail: null,
                isFetchingDetail: false,
                errorDetail: true
            }
        case "SET_SELECTED_REFUND":
            var detail = state.orderDetail;
            detail.cart.cartItems[action.payload.index]['selected'] = action.payload.value;
            if (action.payload.value === false) {
                detail.cart.cartItems[action.payload.index]['quantityList'].map((item, i) => {
                    item['selected'] = i === 0 ? true : false;
                    item['selectedReason'] = "";
                    item['otherText'] = "";
                })
            }
            return {
                ...state,
                orderDetail: detail
            }
        case "SET_SELECTED_REASON":
            var detail = state.orderDetail;
            detail.cart.cartItems[action.payload.index]['selectedReason'] = action.payload.value;
            if(action.payload.value !== "other"){
                detail.cart.cartItems[action.payload.index]['otherText'] = "";
            }
            return {
                ...state,
                orderDetail: detail
            }
        case "SET_OTHER_REASON":
            var detail = state.orderDetail;
            detail.cart.cartItems[action.payload.index]['otherText'] = action.payload.value;
            return {
                ...state,
                orderDetail: detail
            }
        case "SET_SELECTED_QUANTITY":
            var detail = state.orderDetail;
            for (let item of detail.cart.cartItems[action.payload.indexRefund]['quantityList']) {
                item['selected'] = false;
                item['selectedReason'] = "";
                item['otherText'] = "";
            }
            for (let item of detail.cart.cartItems[action.payload.indexRefund]['quantityList']) {
                if (String(item.value) === String(action.payload.value)) {
                    item['selected'] = true;
                    break;
                }
            }

            return {
                ...state,
                orderDetail: detail
            }
        case "GET_CREATE_REFUND":
            return {
                ...state,
                isFetchingCreate: true,
                error: false
            }
        case "FETCH_CREATE_REFUND":
            return {
                ...state,
                isFetchingCreate: false,
                orderDetail: action.payload
            }
        case "ERROR_CREATE_REFUND":
            return {
                ...state,
                isFetchingCreate: false,
                error: true
            }
        case "GET_CANCEL_REFUND":
            var data = state.data;
            data[action.index]['isFetching'] = true;
            return {
                ...state,
                data: data,
                error: false
            }
        case "FETCH_CANCEL_REFUND":
            var data = state.data;
            data[action.index] = action.payload;
            return {
                ...state,
                data: data
            }
        case "ERROR_CANCEL_REFUND":
            var data = state.data;
            data[action.index]['isFetching'] = true;
            return {
                ...state,
                data: data,
                error: true
            }
        default:
            return state
    }

}
