const initialState = {
    data: [],
    installments: [],
    productCount: 0,
    totalPrice: 0,
    shippingPrice : 0,
    discount: 0,
    lastPrice: 0,
    isFetching: false,
    isFetchingCart: false,
    error: false
}

export default function (state = initialState, action) {
    switch (action.type) {

        case "GET_USER_CART_DETAIL":
            return {
                ...state,
                error: false,
                isFetching: true
            }
        case "FETCH_USER_CART_DETAIL":
            return {
                ...state,
                isFetching: false,
                data: action.payload.products,
                productCount: action.payload.count,
                totalPrice: action.payload.totalPrice,
                lastPrice: action.payload.lastPrice,
                shippingPrice : action.payload.shippingPrice,
                discount: action.payload.discount,
                installments: action.payload.installments
            }
        case "ERROR_USER_CART_DETAIL":
            return {
                ...state,
                error: true,
                isFetching: false
            }
        case "EMPTY_USER_CART":
            return {
                ...state,
                data: [],
                productCount: 0
            }
        case "GET_ADD_TO_CART":
            return {
                ...state,
                error: false,
                isFetchingCart: true
            }
        case "FETCH_ADD_TO_CART":
            return {
                ...state,
                isFetchingCart: false
            }
        case "ERROR_ADD_TO_CART":
            return {
                ...state,
                error: true,
                isFetchingCart: false
            }
        default:
            return state
    }
}
