
const initialState = {
    isFetching: false,
    error: false,
    name: "",
    name_helpertext: "",
    name_error: false,
    surname: "",
    surname_error: false,
    surname_helpertext: "",
    email: "",
    email_helpertext: "",
    email_error: false,
    password: "",
    password_helpertext: "",
    password_error: false,
    isConfirmedMarketing: true
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "SET_VALUE_REGISTER":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_REGISTER":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_REGISTER":
            return {
                ...state,
                isFetching: true
            }
        case "FETCH_REGISTER":
            return {
                ...state,
                isFetching: false,
                name: "",
                name_helpertext: "",
                name_error: false,
                surname: "",
                surname_error: false,
                surname_helpertext: "",
                email: "",
                email_helpertext: "",
                email_error: false,
                password: "",
                password_helpertext: "",
                password_error: false,
                isConfirmedMarketing: true
            }
        case "ERROR_REGISTER":
            return {
                ...state,
                isFetching: false,
                error: true
            }

        default:
            return state
    }

}
