
const initialState = {
    isFetching: false,
    error: false,
    email: "",
    email_helpertext: "",
    email_error: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "SET_VALUE_SUBSCRIPTION":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_SUBSCRIPTION":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_SUBSCRIPTION":
            return {
                ...state,
                isFetching: true
            }
        case "FETCH_SUBSCRIPTION":
            return {
                ...state,
                isFetching: false,
                email: "",
                email_helpertext: "",
                email_error: false
            }
        case "ERROR_SUBSCRIPTION":
            return {
                ...state,
                isFetching: false,
                error: true
            }

        default:
            return state
    }

}
