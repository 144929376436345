import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import wishlistReducer from "./wishlistReducer";
import homeReducer from "./homeReducer";
import loginReducer from "./loginReducer";
import registerReducer from "./registerReducer";
import subscriptionReducer from "./subscriptionReducer";
import contactReducer from "./contactReducer";
import productDetailReducer from "./productDetailReducer";
import informReducer from "./informReducer";
import shoppingCartReducer from "./shoppingCartReducer";
import orderReducer from "./orderReducer";
import accountReducer from "./accountReducer";
import checkoutReducer from "./checkoutReducer";
import shopPageReducer from "./shopPageReducer";
import paymentReducer from "./paymentReducer";
import refundRequestReducer from "./refundRequestReducer";

import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "tr" }),
  currencyData: currencyReducer,
  productData: productReducer,
  shoppingCart: shoppingCartReducer,
  wishlist: wishlistReducer,
  home: homeReducer,
  login: loginReducer,
  register: registerReducer,
  subscription: subscriptionReducer,
  productDetail: productDetailReducer,
  contact: contactReducer,
  inform: informReducer,
  order: orderReducer,
  account: accountReducer,
  checkout: checkoutReducer,
  shopPage: shopPageReducer,
  payment: paymentReducer,
  refundRequest: refundRequestReducer
});

export default rootReducer;
