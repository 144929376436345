
const initialState = {
    isFetching: false,
    error: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_INFORM":
            return {
                ...state,
                isFetching: true
            }
        case "FETCH_INFORM":
            return {
                ...state,
                isFetching: false
            }
        case "ERROR_INFORM":
            return {
                ...state,
                isFetching: false,
                error: true
            }

        default:
            return state
    }

}
