
const initialState = {
    isFetching: false,
    error: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "GET_SEND_PAYMENT":
            return {
                ...state,
                isFetching: true
            }
        case "FETCH_SEND_PAYMENT":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            }
        case "ERROR_SEND_PAYMENT":
            return {
                ...state,
                isFetching: false,
                error: true
            }

        case "STOP_LOADING_PAYMENT":
            return {
                ...state,
                isFetching: false
            }

        default:
            return state
    }

}
