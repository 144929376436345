
const initialState = {
    email: "",
    email_helpertext: "",
    email_error: false,
    password: "",
    password_helpertext: "",
    password_error: false,
    isFetching: false,
    isFetchingEmail: false,
    isFetchingPassword: false,
    isFetchingAccess: false,
    hasAccess: true,
    error: false,
    email_forgot: "",
    email_forgot_helpertext: "",
    email_forgot_error: false,
    password_forgot: "",
    password_forgot_helpertext: "",
    password_forgot_error: false,
    repassword_forgot: "",
    repassword_forgot_helpertext: "",
    repassword_forgot_error: false,
    user: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "SET_VALUE_LOGIN":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: "",
                repassword_forgot_error: false,
                password_forgot_error: false
            }
        case "SET_VALUE_ERROR_LOGIN":
            return {
                ...state,
                isFetching: false,
                isFetchingEmail: false,
                isFetchingPassword: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_LOGIN":
            return {
                ...state,
                isFetching: true
            }
        case "FETCH_LOGIN":
            return {
                ...state,
                isFetching: false,
                user: action.payload,
                email: "",
                password: ""
            }
        case "ERROR_LOGIN":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "GET_FORGOT_PASSWORD":
            return {
                ...state,
                isFetchingEmail: true,
                error_forgot: false
            }
        case "FETCH_FORGOT_PASSWORD":
            return {
                ...state,
                email_forgot : "",
                isFetchingEmail: false
            }
        case "ERROR_FORGOT_PASSWORD":
            return {
                ...state,
                isFetchingEmail: false,
                error_forgot: true
            }
        case "GET_CHECK_ACCESS":
            return {
                ...state,
                isFetchingAccess: true,
                error_access: false
            }
        case "FETCH_CHECK_ACCESS":
            return {
                ...state,
                hasAccess: true,
                isFetchingAccess: false
            }
        case "ERROR_CHECK_ACCESS":
            return {
                ...state,
                hasAccess: false,
                isFetchingAccess: false,
                error_access: true
            }
        case "GET_RESET_PASSWORD":
            return {
                ...state,
                isFetchingPassword: true,
                error_password: false
            }
        case "FETCH_RESET_PASSWORD":
            return {
                ...state,
                isFetchingPassword: false,
                password_forgot : "",
                repassword_forgot : ""
            }
        case "ERROR_RESET_PASSWORD":
            return {
                ...state,
                isFetchingPassword: false,
                error_password: true
            }
        default:
            return state
    }

}
