
const initialState = {
    products: [],
    categories: [],
    colors: [],
    uniqueProducts: [],
    isFetching: false,
    error: false,
    currentPage: 1,
    offset: 0,
    currentData: [],
    sortedProducts: [],
    selectedFilterValue: "default",
    selectedCategory: "all",
    selectedColor: "all",
    selectedProduct: "all"
}

function getQueryField(query) {
    let types = ["category", "product", "color", "price"];
    for (let type of types) {
        if (query.get(type) !== null) {
            return type;
        }
    }
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "SET_FILTER_VALUE":
            if (action.payload.key === "offset") {
                return {
                    ...state,
                    [action.payload.key]: action.payload.value,
                    currentData: state.sortedProducts.slice(action.payload.value, action.payload.value + 6)
                }
            } else {
                return {
                    ...state,
                    [action.payload.key]: action.payload.value
                }
            }
        case "ON_CHANGE_FILTER":
            let queryResult = [];
            let sortProducts = [];
            switch (action.payload.filterType) {
                case "category":
                    if (action.payload.value !== "all") {
                        queryResult = state.products.filter(
                            product => product.product.category.filter(single => single.categoryCode === action.payload.value)[0]
                        );
                    } else {
                        queryResult = state.products;
                    }
                    if (state.selectedProduct !== "all" && state.selectedColor !== "all") {
                        queryResult = queryResult.filter(product => product.color.colorCode === state.selectedColor && product.product.seoName === state.selectedProduct);
                    } else if (state.selectedProduct === "all" && state.selectedColor !== "all") {
                        queryResult = queryResult.filter(product => product.color.colorCode === state.selectedColor);
                    } else if (state.selectedProduct !== "all" && state.selectedColor === "all") {
                        queryResult = queryResult.filter(product => product.product.seoName === state.selectedProduct);
                    }

                    sortProducts = queryResult;
                    if (state.selectedFilterValue === "default") {
                        queryResult = sortProducts;
                    }
                    if (state.selectedFilterValue === "priceHighToLow") {
                        queryResult = sortProducts.sort((a, b) => {
                            return b.product.discountedPrice.try - a.product.discountedPrice.try;
                        });
                    }
                    if (state.selectedFilterValue === "priceLowToHigh") {
                        queryResult = sortProducts.sort((a, b) => {
                            return a.product.discountedPrice.try - b.product.discountedPrice.try;
                        });
                    }

                    break;
                case "color":
                    if (action.payload.value !== "all") {
                        queryResult = state.products.filter(product => product.color.colorCode === action.payload.value);
                    } else {
                        queryResult = state.products;
                    }
                    if (state.selectedCategory !== "all" && state.selectedProduct !== "all") {
                        queryResult = queryResult.filter(
                            product => product.product.category.filter(single => single.categoryCode === state.selectedCategory)[0]
                        );
                        queryResult = queryResult.filter(product => product.product.seoName === state.selectedProduct);
                    } else if (state.selectedCategory === "all" && state.selectedProduct !== "all") {
                        queryResult = queryResult.filter(product => product.product.seoName === state.selectedProduct);
                    } else if (state.selectedCategory !== "all" && state.selectedProduct === "all") {
                        queryResult = queryResult.filter(
                            product => product.product.category.filter(single => single.categoryCode === state.selectedCategory)[0]
                        );
                    }

                    sortProducts = queryResult;
                    if (state.selectedFilterValue === "default") {
                        queryResult = sortProducts;
                    }
                    if (state.selectedFilterValue === "priceHighToLow") {
                        queryResult = sortProducts.sort((a, b) => {
                            return b.product.discountedPrice.try - a.product.discountedPrice.try;
                        });
                    }
                    if (state.selectedFilterValue === "priceLowToHigh") {
                        queryResult = sortProducts.sort((a, b) => {
                            return a.product.discountedPrice.try - b.product.discountedPrice.try;
                        });
                    }

                    break;
                case "product":
                    if (action.payload.value !== "all") {
                        queryResult = state.products.filter(product => product.product.seoName === action.payload.value);
                    } else {
                        queryResult = state.products;
                    }
                    if (state.selectedCategory !== "all" && state.selectedColor !== "all") {
                        queryResult = queryResult.filter(
                            product => product.product.category.filter(single => single.categoryCode === state.selectedCategory)[0]
                        );
                        queryResult = queryResult.filter(product => product.color.colorCode === state.selectedColor);
                    } else if (state.selectedCategory === "all" && state.selectedColor !== "all") {
                        queryResult = queryResult.filter(product => product.color.colorCode === state.selectedColor);
                    } else if (state.selectedCategory !== "all" && state.selectedColor === "all") {
                        queryResult = queryResult.filter(
                            product => product.product.category.filter(single => single.categoryCode === state.selectedCategory)[0]
                        );
                    }

                    sortProducts = queryResult;
                    if (state.selectedFilterValue === "default") {
                        queryResult = sortProducts;
                    }
                    if (state.selectedFilterValue === "priceHighToLow") {
                        queryResult = sortProducts.sort((a, b) => {
                            return b.product.discountedPrice.try - a.product.discountedPrice.try;
                        });
                    }
                    if (state.selectedFilterValue === "priceLowToHigh") {
                        queryResult = sortProducts.sort((a, b) => {
                            return a.product.discountedPrice.try - b.product.discountedPrice.try;
                        });
                    }


                    break;
                case "price":
                    sortProducts = [...state.products];
                    if (action.payload.value === "default") {
                        queryResult = sortProducts;
                    }
                    if (action.payload.value === "priceHighToLow") {
                        queryResult = sortProducts.sort((a, b) => {
                            return b.product.discountedPrice.try - a.product.discountedPrice.try;
                        });
                    }
                    if (action.payload.value === "priceLowToHigh") {
                        queryResult = sortProducts.sort((a, b) => {
                            return a.product.discountedPrice.try - b.product.discountedPrice.try;
                        });
                    }
                    break;
            }

            window.scrollTo(0, 0);
            if (action.payload.filterType === "price") {
                return {
                    ...state,
                    [action.payload.key]: action.payload.value,
                    selectedProduct: "all",
                    selectedColor: "all",
                    selectedCategory: "all",
                    sortedProducts: queryResult,
                    offset: 0,
                    currentData: queryResult.slice(0, 6)
                }
            } else {
                return {
                    ...state,
                    [action.payload.key]: action.payload.value,
                    sortedProducts: queryResult,
                    currentData: queryResult.slice(state.offset, state.offset + 6)
                }
            }
        case "CLEAR_FILTER":
            return {
                ...state,
                sortedProducts: state.products,
                offset: 0,
                currentData: state.products.slice(0, 6),
                selectedCategory: "all",
                selectedColor: "all",
                selectedProduct: "all",
                selectedFilterValue: "default"
            }

        case "GET_SHOP_PRODUCTS":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_SHOP_PRODUCTS":
            let query = action.query;
            let queryType = getQueryField(query);
            let key = "";
            queryResult = [];
            sortProducts = [];
            switch (queryType) {
                case "category":
                    key = "selectedCategory";
                    state.selectedFilterValue = "default";
                    state.selectedProduct = "all";
                    state.selectedColor = "all";
                    if (query.get("category") !== "all") {
                        queryResult = action.payload.filter(
                            product => product.product.category.filter(single => single.categoryCode === query.get("category"))[0]
                        );
                    } else {
                        queryResult = action.payload;
                    }

                    break;
                case "color":
                    key = "selectedColor";
                    state.selectedFilterValue = "default";
                    state.selectedProduct = "all";
                    state.selectedCategory = "all";
                    if (query.get("color") !== "all") {
                        queryResult = action.payload.filter(product => product.color.colorCode === query.get("color"));
                    } else {
                        queryResult = action.payload;
                    }

                    break;
                case "product":
                    key = "selectedProduct";
                    state.selectedFilterValue = "default";
                    state.selectedCategory = "all";
                    state.selectedColor = "all";
                    if (query.get("product") !== "all") {
                        queryResult = action.payload.filter(product => product.product.seoName === query.get("product"));
                    } else {
                        queryResult = action.payload;
                    }

                    break;
                case "price":
                    key = "selectedFilterValue";
                    state.selectedProduct = "all";
                    state.selectedColor = "all";
                    state.selectedCategory = "all";

                    sortProducts = [...state.products];
                    if (query.get("price") === "default") {
                        queryResult = sortProducts;
                    }
                    if (query.get("price") === "priceHighToLow") {
                        queryResult = sortProducts.sort((a, b) => {
                            return b.product.discountedPrice.try - a.product.discountedPrice.try;
                        });
                    }
                    if (query.get("price") === "priceLowToHigh") {
                        queryResult = sortProducts.sort((a, b) => {
                            return a.product.discountedPrice.try - b.product.discountedPrice.try;
                        });
                    }
                    break;
            }

            if (queryType) {
                return {
                    ...state,
                    isFetching: false,
                    products: action.payload,
                    sortedProducts: queryResult,
                    currentData: queryResult.slice(0, 6),
                    categories: action.uniqueCategories,
                    colors: action.uniqueColors,
                    uniqueProducts: action.uniqueProducts,
                    [key]: query.get(queryType)
                }
            } else {
                return {
                    ...state,
                    isFetching: false,
                    products: action.payload,
                    sortedProducts: action.payload,
                    currentData: action.payload.slice(0, 6),
                    categories: action.uniqueCategories,
                    colors: action.uniqueColors,
                    uniqueProducts: action.uniqueProducts,
                    selectedFilterValue: "default",
                    selectedCategory: "all",
                    selectedColor: "all",
                    selectedProduct: "all"
                }
            }

        case "ERROR_SHOP_PRODUCTS":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        default:
            return state
    }

}
