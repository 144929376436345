
const initialState = {
    isFetching: false,
    error: false,
    name: "",
    name_helpertext: "",
    name_error: false,
    email: "",
    email_helpertext: "",
    email_error: false,
    subject: "",
    subject_helpertext: "",
    subject_error: false,
    message: "",
    message_helpertext: "",
    message_error: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "SET_VALUE_CONTACT":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_CONTACT":
            return {
                ...state,
                isFetching: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_CONTACT":
            return {
                ...state,
                isFetching: true
            }
        case "FETCH_CONTACT":
            return {
                ...state,
                isFetching: false,
                name: "",
                name_helpertext: "",
                name_error: false,
                email: "",
                email_helpertext: "",
                email_error: false,
                subject: "",
                subject_helpertext: "",
                subject_error: false,
                message: "",
                message_helpertext: "",
                message_error: false
            }
        case "ERROR_CONTACT":
            return {
                ...state,
                isFetching: false,
                error: true
            }

        default:
            return state
    }

}
