
const initialState = {
  data: [],
  count: 0,
  isFetching: false,
  error: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case "ADD_TO_WISHLIST":
      state.data.push(action.payload);
      return {
        ...state,
        count: state.data.length
      }
    case "CLEAR_FROM_WISHLIST":
      state.data.splice(action.index, 1);
      return {
        ...state,
        count: state.data.length
      }
    case "CLEAR_ALL_WISHLIST":
      return {
        ...state,
        count: 0,
        data: []
      }
    case "GET_ON_CHANGE_PRODUCT_WISHLIST":
      var data = state.data;
      data[action.index]['isChanging'] = true;
      return {
        ...state,
        data: data,
        error: false
      }
    case "FETCH_ON_CHANGE_PRODUCT_WISHLIST":
      var obj = {
        ...action.payload.data,
        selectedColor: action.payload.data.color.id,
        selectedSize: "",
        isChanging: false
      };
      state.data[action.index] = obj;
      return {
        ...state,
        [state.data[action.index]['isChanging']]: false,
      }
    case "ERROR_ON_CHANGE_PRODUCT_WISHLIST":
      return {
        ...state,
        [state.data[action.index]['isChanging']]: false,
        error: true
      }
    case "SET_SELECT_PRODUCT_WISHLIST":
      data = state.data;
      data[action.payload.index][action.payload.key] = action.payload.value;
      return {
        ...state,
        data: data
      }
    case "GET_USER_WISHLIST":
      return {
        ...state,
        isFetching: true,
        error: false
      }
    case "FETCH_USER_WISHLIST":
      return {
        ...state,
        data: action.payload,
        count: action.payload.length,
        isFetching: false,
      }
    case "ERROR_USER_WISHLIST":
      return {
        ...state,
        isFetching: false,
        error: false
      }
    default:
      return state
  }
}


