
const initialState = {
    banners: [],
    imageSliders: [],
    isFetching: false,
    error: false,

}

export default function (state = initialState, action) {
    console.log(action)
    switch (action.type) {
        case "GET_HOME":
            return {
                ...state,
                isFetching: true,
                error: false
            }
        case "FETCH_HOME":
            return {
                ...state,
                isFetching: false,
                banners: action.payload.banners,
                imageSliders: action.payload.imageSliders.length > 0 ? action.payload.imageSliders[0].images : []
            }

        case "ERROR_HOME":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        default:
            return state
    }

}
