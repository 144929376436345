
const initialState = {
    cities: [],
    districts: [],
    streets: [],
    name: "",
    name_helpertext: "",
    name_error: false,
    surname: "",
    surname_helpertext: "",
    surname_error: false,
    email: "",
    email_helpertext: "",
    email_error: false,
    phone: "",
    phone_helpertext: "",
    phone_error: false,
    password: "",
    password_helpertext: "",
    password_error: false,
    repassword: "",
    repassword_helpertext: "",
    repassword_error: false,
    addresses: [],
    addresses_helpertext: "",
    addresses_error: false,
    isFetching: false,
    isFetchingInfo: false,
    isFetchingPassword: false,
    isFetchingAddress: false,
    isFetchingActions: false,
    error: false,
    title: "",
    title_helpertext: "",
    title_error: false,
    nameSurname: "",
    nameSurname_helpertext: "",
    nameSurname_error: false,
    tcNumber: "",
    tcNumber_helpertext: "",
    tcNumber_error: false,
    postalCode: "",
    isPersonalInvoice: true,
    city: "",
    city_helpertext: "",
    city_error: false,
    district: "",
    district_helpertext: "",
    district_error: false,
    street: "",
    street_helpertext: "",
    street_error: false,
    address: "",
    address_helpertext: "",
    address_error: false,
    mobilePhone: "",
    mobilePhone_helpertext: "",
    mobilePhone_error: false,
    firmName: "",
    firmName_helpertext: "",
    firmName_error: false,
    taxNumber: "",
    taxNumber_helpertext: "",
    taxNumber_error: false,
    taxOffice: "",
    taxOffice_helpertext: "",
    taxOffice_error: false,

    isFetchingDistricts: false,
    isFetchingStreets: false,
    selectedAddress: "",
    orderNote: "",
    isSending: false,
    userAddress: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "TOGGLE_CHECKOUT_MODAL":
            return {
                ...state,
                showModal: action.payload
            }
        case "SET_VALUE_CHECKOUT":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_CHECKOUT":
            return {
                ...state,
                isFetching: false,
                isFetchingInfo: false,
                isSending : false,
                isFetchingPassword: false,
                isFetchingAddress: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_CHECKOUT":
            return {
                ...state,
                isFetching: true
            }
        case "FETCH_CHECKOUT":
            let activeAddress = "";
            for (let item of action.payload.account.address) {
                if (item.isActive === true) {
                    activeAddress = item.id;
                }
            }

            if (activeAddress === "" && action.payload.account.address.length > 0) {
                activeAddress = action.payload.account.address[0].id;
            }

            return {
                ...state,
                isFetching: false,
                isSending : false,
                user: action.payload.account,
                name: action.payload.account.name,
                surname: action.payload.account.surname,
                email: action.payload.account.email,
                phone: action.payload.account.phone,
                addresses: action.payload.account.address,
                selectedAddress: activeAddress,
                cities: action.payload.cities,
                name_helpertext: "",
                name_error: false,
                surname_helpertext: "",
                surname_error: false,
                phone_helpertext: "",
                phone_error: false,
                password: "",
                password_helpertext: "",
                password_error: false,
                repassword: "",
                repassword_helpertext: "",
                repassword_error: false,
                orderNote: "",
                title: "",
                title_helpertext: "",
                title_error: false,
                nameSurname: "",
                nameSurname_helpertext: "",
                nameSurname_error: false,
                tcNumber: "",
                tcNumber_helpertext: "",
                tcNumber_error: false,
                postalCode: "",
                isPersonalInvoice: true,
                city: "",
                city_helpertext: "",
                city_error: false,
                district: "",
                district_helpertext: "",
                district_error: false,
                street: "",
                street_helpertext: "",
                street_error: false,
                address: "",
                address_helpertext: "",
                address_error: false,
                mobilePhone: "90",
                mobilePhone_helpertext: "",
                mobilePhone_error: false,
                firmName: "",
                firmName_helpertext: "",
                firmName_error: false,
                taxNumber: "",
                taxNumber_helpertext: "",
                taxNumber_error: false,
                taxOffice: "",
                taxOffice_helpertext: "",
                taxOffice_error: false
            }
        case "ERROR_CHECKOUT":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "GET_CHECKOUT_DISTRICTS":
            return {
                ...state,
                isFetchingDistricts: true,
                streets: [],
                error: false
            }
        case "FETCH_CHECKOUT_DISTRICTS":
            return {
                ...state,
                districts: action.payload,
                isFetchingDistricts: false
            }
        case "ERROR_CHECKOUT_DISTRICTS":
            return {
                ...state,
                isFetchingDistricts: false,
                error: true
            }
        case "GET_CHECKOUT_STREETS":
            return {
                ...state,
                isFetchingStreets: true,
                error: false
            }
        case "FETCH_CHECKOUT_STREETS":
            return {
                ...state,
                isFetchingStreets: false,
                streets: action.payload
            }
        case "ERROR_CHECKOUT_STREETS":
            return {
                ...state,
                isFetchingStreets: false,
                error: true
            }
        case "GET_CITIES":
            return {
                ...state,
                isFetching: true
            }
        case "FETCH_CITIES":
            return {
                ...state,
                isFetching: false,
                cities: action.payload,
                name: "",
                name_helpertext: "",
                name_error: false,
                surname: "",
                surname_helpertext: "",
                surname_error: false,
                email: "",
                email_helpertext: "",
                email_error: false,
                phone: "",
                phone_helpertext: "",
                phone_error: false,
                password: "",
                password_helpertext: "",
                password_error: false,
                repassword: "",
                repassword_helpertext: "",
                repassword_error: false,
                addresses: [],
                addresses_helpertext: "",
                addresses_error: false,
                isFetching: false,
                isFetchingInfo: false,
                isFetchingPassword: false,
                isFetchingAddress: false,
                isFetchingActions: false,
                error: false,
                title: "",
                title_helpertext: "",
                title_error: false,
                nameSurname: "",
                nameSurname_helpertext: "",
                nameSurname_error: false,
                tcNumber: "",
                tcNumber_helpertext: "",
                tcNumber_error: false,
                postalCode: "",
                isPersonalInvoice: true,
                city: "",
                city_helpertext: "",
                city_error: false,
                district: "",
                district_helpertext: "",
                district_error: false,
                street: "",
                street_helpertext: "",
                street_error: false,
                address: "",
                address_helpertext: "",
                address_error: false,
                mobilePhone: "",
                mobilePhone_helpertext: "",
                mobilePhone_error: false,
                firmName: "",
                firmName_helpertext: "",
                firmName_error: false,
                taxNumber: "",
                taxNumber_helpertext: "",
                taxNumber_error: false,
                taxOffice: "",
                orderNote: "",
                taxOffice_helpertext: "",
                taxOffice_error: false
            }
        case "ERROR_CITIES":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "GET_SEND_CHECKOUT":
            return {
                ...state,
                isSending: true,
                error: false
            }
        case "FETCH_SEND_CHECKOUT":
            return {
                ...state,
                isSending: false
            }
        case "ERROR_SEND_CHECKOUT":
            return {
                ...state,
                isSending: false,
                error: true
            }
        case "SET_USER_ACTIVE_ADDRESS":
            return {
                ...state,
                userAddress: action.payload
            }
        default:
            return state
    }

}
