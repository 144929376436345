import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { DefaultToastContainer, DefaultToast } from 'react-toast-notifications';
import { isMobile } from "react-device-detect";
import ReactGA from 'react-ga';

const MyCustomToastContainer = (props) => (
  <DefaultToastContainer {...props} style={{ zIndex: 9999 }} />
)

const MyCustomToast = ({ children, ...props }) => (
  <DefaultToast {...props}>
    <div style={{
      flex: 1,
      fontSize: isMobile ? 21 : 18,
      display: "flex",
      padding: 10
    }}>
      {children}
    </div>
  </DefaultToast>

);


// home page
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));
// shop page
const ShopPage = lazy(() => import("./pages/shop/ShopPage"));
// product page
const Product = lazy(() => import("./pages/shop-product/Product"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const MyOrders = lazy(() => import("./pages/other/MyOrders"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const ForgotPassword = lazy(() => import("./pages/other/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/other/ResetPassword"));
const Payment = lazy(() => import("./pages/other/Payment"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const Success = lazy(() => import("./pages/other/Success"));
const Error = lazy(() => import("./pages/other/Error3D"));
const OrderDetail = lazy(() => import("./pages/other/OrderDetail"));
const CreateRefundRequest = lazy(() => import("./pages/other/CreateRefundRequest"));
const RefundRequests = lazy(() => import("./pages/other/RefundRequests"));

const App = (props) => {
  useEffect(() => {
    ReactGA.initialize('G-LJ69PDNDVR');
    ReactGA.pageview(window.location.pathname + window.location.search);
    props.dispatch(
      loadLanguages({
        languages: {
          tr: require("./translations/turkish.json"),
          eng: require("./translations/english.json")
        }
      })
    );
  });

  return (
    <ToastProvider components={{ Toast: MyCustomToast, ToastContainer: MyCustomToastContainer }} placement="top-right">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="iola-preloader-wrapper">
                  <div className="iola-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={HomeFashion}
                />

                {/* Homepages */}
                <Route
                  path={process.env.PUBLIC_URL + "/home"}
                  component={HomeFashion}
                />


                {/* Shop pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/shop"}
                  component={ShopPage}
                />

                {/* Shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:productSeo/:colorCode"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/my-account/:action"}
                  component={MyAccount}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/my-orders"}
                  component={MyOrders}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/login-register/:action"}
                  component={LoginRegister}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/forgot-password"}
                  component={ForgotPassword}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/reset-password/:token"}
                  component={ResetPassword}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  component={Wishlist}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/payment"}
                  component={Payment}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/payment-success/:orderNumber"}
                  component={Success}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/payment-error/:errorCode"}
                  component={Error}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/order-detail/:orderNumber"}
                  component={OrderDetail}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/refund-request/:orderNumber"}
                  component={CreateRefundRequest}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/my-refunds"}
                  component={RefundRequests}
                />

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(multilanguage(App));
