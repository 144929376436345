const initialState = {
    products: [],
    detail: null,
    showProductModal: false,
    isFetching: false,
    isChanging: false,
    selectedImages: [],
    stockData: [],
    selectedColor: "",
    selectedSize: "",
    quantity: 1,
    error: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case "TOGGLE_PRODUCT_DETAIL_MODAL":
            return {
                ...state,
                showProductModal: action.payload
            }
        case "FETCH_PRODUCT_DETAILS_SUCCESS":
            return {
                ...state,
                products: action.payload,
            };
        case "GET_PRODUCT_DETAIL_INFO_MODAL":
            return {
                ...state,
                isFetching: true,
                showProductModal: true,
                error: false
            }
        case "FETCH_PRODUCT_DETAIL_INFO_MODAL":
            var index = action.payload.data.stockData.findIndex(x => x.inStock === true);
            return {
                ...state,
                isFetching: false,
                detail: action.payload.data,
                selectedImages: action.payload.data.images,
                selectedColor: action.payload.data.color.id,
                stockData: action.payload.data.stockData,
                selectedSize: index > -1 ? action.payload.data.stockData[index].size.id : "",
                showProductModal: true,
                quantity: 1
            }
        case "ERROR_PRODUCT_DETAIL_INFO_MODAL":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "SET_SELECT_PRODUCT_DETAIL_INFO_MODAL":
            if (action.payload.key === "selectedColor") {
                var index = state.detail.product.stockData.findIndex(x => String(x.color.id) === String(action.payload.value));
                if (index > -1) {
                    state.selectedImages = state.detail.product.stockData[index]['images'];
                }
            }
            return {
                ...state,
                [action.payload.key]: action.payload.value
            }
        case "GET_ON_CHANGE_PRODUCT_DETAIL_INFO_MODAL":
            return {
                ...state,
                isChanging: true,
                error: false
            }
        case "FETCH_ON_CHANGE_PRODUCT_DETAIL_INFO_MODAL":
            var index = action.payload.data.stockData.findIndex(x => x.inStock === true);
            return {
                ...state,
                isChanging: false,
                detail: action.payload.data[0],
                selectedImages: action.payload.data[0].images,
                selectedColor: action.payload.data[0].color.id,
                stockData: action.payload.data[0].stockData,
                selectedSize: index > -1 ? action.payload.data.stockData[index].size.id : "",
            }
        case "ERROR_ON_CHANGE_INFO_MODAL":
            return {
                ...state,
                isChanging: false,
                error: true
            }
        case "SET_INC_QUANTITY_PRODUCT_DETAIL_INFO_MODAL":
            return {
                ...state,
                quantity: state.quantity + 1
            }
        case "SET_DEC_QUANTITY_PRODUCT_DETAIL_INFO_MODAL":
            return {
                ...state,
                quantity: state.quantity - 1 > 1 ? state.quantity - 1 : 1
            }
        default:
            return state
    }
}

