
const initialState = {
    cities: [],
    districts: [],
    streets: [],
    name: "",
    name_helpertext: "",
    name_error: false,
    surname: "",
    surname_helpertext: "",
    surname_error: false,
    email: "",
    email_helpertext: "",
    email_error: false,
    phone: "",
    phone_helpertext: "",
    phone_error: false,
    password: "",
    password_helpertext: "",
    password_error: false,
    repassword: "",
    repassword_helpertext: "",
    repassword_error: false,
    addresses: [],
    addresses_helpertext: "",
    addresses_error: false,
    isFetching: false,
    isFetchingInfo: false,
    isFetchingPassword: false,
    isFetchingAddress: false,
    isFetchingActions: false,
    error: false,
    user: null,
    showModal: false,
    title: "",
    title_helpertext: "",
    title_error: false,
    nameSurname: "",
    nameSurname_helpertext: "",
    nameSurname_error: false,
    tcNumber: "",
    tcNumber_helpertext: "",
    tcNumber_error: false,
    postalCode: "",
    isPersonalInvoice: true,
    city: "",
    city_helpertext: "",
    city_error: false,
    district: "",
    district_helpertext: "",
    district_error: false,
    street: "",
    street_helpertext: "",
    street_error: false,
    address: "",
    address_helpertext: "",
    address_error: false,
    mobilePhone: "",
    mobilePhone_helpertext: "",
    mobilePhone_error: false,
    firmName: "",
    firmName_helpertext: "",
    firmName_error: false,
    taxNumber: "",
    taxNumber_helpertext: "",
    taxNumber_error: false,
    taxOffice: "",
    taxOffice_helpertext: "",
    taxOffice_error: false,
    // detail
    title_detail: "",
    title_detail_helpertext: "",
    title_detail_error: false,
    nameSurname_detail: "",
    nameSurname_detail_helpertext: "",
    nameSurname_detail_error: false,
    tcNumber_detail: "",
    tcNumber_detail_helpertext: "",
    tcNumber_detail_error: false,
    postalCode_detail: "",
    isPersonalInvoice_detail: true,
    city_detail: "",
    city_detail_helpertext: "",
    city_detail_error: false,
    district_detail: "",
    district_detail_helpertext: "",
    district_detail_error: false,
    street_detail: "",
    street_detail_helpertext: "",
    street_detail_error: false,
    address_detail: "",
    address_detail_helpertext: "",
    address_detail_error: false,
    mobilePhone_detail: "",
    mobilePhone_detail_helpertext: "",
    mobilePhone_detail_error: false,
    firmName_detail: "",
    firmName_detail_helpertext: "",
    firmName_detail_error: false,
    taxNumber_detail: "",
    taxNumber_detail_helpertext: "",
    taxNumber_detail_error: false,
    taxOffice_detail: "",
    taxOffice_detail_helpertext: "",
    taxOffice_detail_error: false,


    isFetchingDistricts: false,
    isFetchingStreets: false,
    addressDetail: null,
    addressIndex: 0,
    process: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case "TOGGLE_ACCOUNT_MODAL":
            return {
                ...state,
                showModal: action.payload
            }
        case "SET_VALUE_ACCOUNT":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                [action.payload.key + '_error']: false,
                [action.payload.key + '_helpertext']: ""
            }
        case "SET_VALUE_ERROR_ACCOUNT":
            return {
                ...state,
                isFetching: false,
                isFetchingInfo: false,
                isFetchingPassword: false,
                isFetchingActions : false,
                isFetchingAddress: false,
                [action.payload.key + '_error']: true,
                [action.payload.key + '_helpertext']: action.payload.helpertext
            }
        case "GET_ACCOUNT":
            return {
                ...state,
                isFetching: true,
                isFetchingActions : false
            }
        case "FETCH_ACCOUNT":
            return {
                ...state,
                isFetching: false,
                user: action.payload.account,
                name: action.payload.account.name,
                surname: action.payload.account.surname,
                email: action.payload.account.email,
                phone: action.payload.account.phone,
                addresses: action.payload.account.address,
                cities: action.payload.cities,
                name_helpertext: "",
                name_error: false,
                surname_helpertext: "",
                surname_error: false,
                phone_helpertext: "",
                phone_error: false,
                password: "",
                password_helpertext: "",
                password_error: false,
                repassword: "",
                repassword_helpertext: "",
                repassword_error: false,

                title: "",
                title_helpertext: "",
                title_error: false,
                nameSurname: "",
                nameSurname_helpertext: "",
                nameSurname_error: false,
                tcNumber: "",
                tcNumber_helpertext: "",
                tcNumber_error: false,
                postalCode: "",
                isPersonalInvoice: true,
                city: "",
                city_helpertext: "",
                city_error: false,
                district: "",
                district_helpertext: "",
                district_error: false,
                street: "",
                street_helpertext: "",
                street_error: false,
                address: "",
                address_helpertext: "",
                address_error: false,
                mobilePhone: "",
                mobilePhone_helpertext: "",
                mobilePhone_error: false,
                firmName: "",
                firmName_helpertext: "",
                firmName_error: false,
                taxNumber: "",
                taxNumber_helpertext: "",
                taxNumber_error: false,
                taxOffice: "",
                taxOffice_helpertext: "",
                taxOffice_error: false
            }
        case "ERROR_ACCOUNT":
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case "GET_UPDATE_USER_INFO_ACCOUNT":
            return {
                ...state,
                isFetchingInfo: true,
                error: false
            }
        case "FETCH_UPDATE_USER_INFO_ACCOUNT":
            return {
                ...state,
                isFetchingInfo: false,
                error: false
            }
        case "ERROR_UPDATE_USER_INFO_ACCOUNT":
            return {
                ...state,
                isFetchingInfo: false,
                error: false
            }
        case "GET_UPDATE_PASSWORD_ACCOUNT":
            return {
                ...state,
                isFetchingPassword: true,
                error: false
            }
        case "FETCH_UPDATE_PASSWORD_ACCOUNT":
            return {
                ...state,
                isFetchingPassword: false,
                password: "",
                password_helpertext: "",
                password_error: false,
                repassword: "",
                repassword_helpertext: "",
                repassword_error: false,
                error: false
            }
        case "ERROR_UPDATE_PASSWORD_ACCOUNT":
            return {
                ...state,
                isFetchingPassword: false,
                error: false
            }
        case "GET_ACCOUNT_DISTRICTS":
            return {
                ...state,
                isFetchingDistricts: true,
                streets: [],
                error: false
            }
        case "FETCH_ACCOUNT_DISTRICTS":
            return {
                ...state,
                districts: action.payload,
                isFetchingDistricts: false
            }
        case "ERROR_ACCOUNT_DISTRICTS":
            return {
                ...state,
                isFetchingDistricts: false,
                error: true
            }
        case "GET_ACCOUNT_STREETS":
            return {
                ...state,
                isFetchingStreets: true,
                error: false
            }
        case "FETCH_ACCOUNT_STREETS":
            return {
                ...state,
                isFetchingStreets: false,
                streets: action.payload
            }
        case "ERROR_ACCOUNT_STREETS":
            return {
                ...state,
                isFetchingStreets: false,
                error: true
            }
        case "GET_CREATE_NEW_ADDRESS":
            return {
                ...state,
                isFetchingAddress: true,
                error: false
            }
        case "FETCH_CREATE_NEW_ADDRESS":
            return {
                ...state,
                addresses: action.payload.address,
                isFetchingAddress: false,
                title: "",
                title_helpertext: "",
                title_error: false,
                nameSurname: "",
                nameSurname_helpertext: "",
                nameSurname_error: false,
                tcNumber: "",
                tcNumber_helpertext: "",
                tcNumber_error: false,
                postalCode: "",
                isPersonalInvoice: true,
                city: "",
                city_helpertext: "",
                city_error: false,
                district: "",
                district_helpertext: "",
                district_error: false,
                street: "",
                street_helpertext: "",
                street_error: false,
                address: "",
                address_helpertext: "",
                address_error: false,
                mobilePhone: "",
                mobilePhone_helpertext: "",
                mobilePhone_error: false,
                firmName: "",
                firmName_helpertext: "",
                firmName_error: false,
                taxNumber: "",
                taxNumber_helpertext: "",
                taxNumber_error: false,
                taxOffice: "",
                taxOffice_helpertext: "",
                taxOffice_error: false
            }
        case "ERROR_CREATE_NEW_ADDRESS":
            return {
                ...state,
                isFetchingAddress: false,
                error: true
            }
        case "SET_ADDRESS_DETAIL":
            state.districts.push(action.payload.item.district);
            state.streets.push(action.payload.item.street);
            return {
                ...state,
                addressDetail: action.payload.item,
                addressIndex: action.payload.index,
                process: action.payload.process,
                title_detail: action.payload.item.title,
                title_detail_helpertext: "",
                title_detail_error: false,
                nameSurname_detail: action.payload.item.nameSurname,
                nameSurname_detail_helpertext: "",
                nameSurname_detail_error: false,
                tcNumber_detail: action.payload.item.tcNumber,
                tcNumber_detail_helpertext: "",
                tcNumber_detail_error: false,
                postalCode_detail: action.payload.item.postalCode,
                isPersonalInvoice_detail: action.payload.item.isPersonalInvoice,
                city_detail: action.payload.item.city.id,
                city_detail_helpertext: "",
                city_detail_error: false,
                district_detail: action.payload.item.district.id,
                district_detail_helpertext: "",
                district_detail_error: false,
                street_detail: action.payload.item.street.id,
                street_detail_helpertext: "",
                street_detail_error: false,
                address_detail: action.payload.item.address,
                address_detail_helpertext: "",
                address_detail_error: false,
                mobilePhone_detail: action.payload.item.phone,
                mobilePhone_detail_helpertext: "",
                mobilePhone_detail_error: false,
                firmName_detail: action.payload.item.firmName,
                firmName_detail_helpertext: "",
                firmName_detail_error: false,
                taxNumber_detail: action.payload.item.taxNumber,
                taxNumber_detail_helpertext: "",
                taxNumber_detail_error: false,
                taxOffice_detail: action.payload.item.taxOffice,
                taxOffice_detail_helpertext: "",
                taxOffice_detail_error: false,
                showModal: true
            }

        case "GET_UPDATE_USER_ADDRESS":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_UPDATE_USER_ADDRESS":
            state.addresses[state.addressIndex] = action.payload;
            return {
                ...state,
                isFetchingActions: false,
                showModal: false
            }
        case "ERROR_UPDATE_USER_ADDRESS":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }

        case "GET_DELETE_USER_ADDRESS":
            return {
                ...state,
                isFetchingActions: true,
                error: false
            }
        case "FETCH_DELETE_USER_ADDRESS":
            state.addresses.splice(state.addressIndex, 1);
            return {
                ...state,
                isFetchingActions: false,
                showModal: false
            }
        case "ERROR_DELETE_USER_ADDRESS":
            return {
                ...state,
                isFetchingActions: false,
                error: true
            }
        default:
            return state
    }

}
